import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class ClientsService {
  private clientsUrl = "entities/clients";

  constructor(private http: HttpClient) {}

  /**
   * Get the details of the current client
   */
  public getCurrent(): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}${this.clientsUrl}/current`
    );
  }

  // GET Client
  getClient(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.clientsUrl + "/" + id
    );
  }

  // PUT Edit Client info
  updateClientDetails(client): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + this.clientsUrl + "/" + client.id,
        client,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // PUT Edit Client account info
  updateClientAccountDetails(client): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          this.clientsUrl +
          "/user/" +
          client.entity_id,
        client,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // When admin wants to login as client
  switchToClient(data): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + "switch-to-client/" + data.token)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Get Status transitions for provider
  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "entities/client/status-transitions"
    );
  }

  updateStatus(statusId, entityId, reason = null): Observable<any> {
    const body = { status_id: statusId };
    if (reason) {
      body["reason"] = reason;
    }
    return this.http.patch(
      environment.gateway_endpoint + "entities/providers/" + entityId,
      body,
      httpOptions
    );
  }

  getClientCountries(clientId): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        "entities/providers/" +
        clientId +
        "/countries"
    );
  }
}
