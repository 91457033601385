import { DOCUMENT } from "@angular/common";
import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { QueryParamsService } from "@services/query-params.service";
import { SvgRegistryService } from "@services/svg-registry.service";
import { WindowResizeHelperService } from "@services/window-resize-helper.service";
import { CookieService } from "ngx-cookie-service";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";

export const COOKIE_CONSENT_COOKIE_KEY = "cookieConsent";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "xpath.global - marketplace";
  innerWidth;
  xs;
  private ccStatusSub: Subscription;

  constructor(
    private router: Router,
    private windowResizeHelper: WindowResizeHelperService,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    private svgRegistryService: SvgRegistryService,
    private queryParams: QueryParamsService,
    private ccService: NgcCookieConsentService,
    private cookieService: CookieService
  ) {
    this.onResize();
    this.windowResizeHelper.sendData(this.xs);
    this.svgRegistryService.init();
  }

  ngOnInit() {
    this.ccService.statusChange$.pipe(take(1)).subscribe((event) => {
      this.cookieService.set(COOKIE_CONSENT_COOKIE_KEY, event.status, 365);
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((route) => {
        let primaryRoute = route["url"].split("/")[1];
        primaryRoute = primaryRoute.split("?")[0];
        primaryRoute =
          primaryRoute.charAt(0).toUpperCase() + primaryRoute.slice(1);

        this.titleService.setTitle(primaryRoute);
      });
    this.windowResizeHelper.sendData(this.xs);
  }

  @HostListener("window:resize")
  onResize() {
    this.innerWidth = this.document.defaultView.innerWidth || 1920;

    this.xs = this.innerWidth <= 992;

    this.windowResizeHelper.sendData(this.xs);
  }
}
