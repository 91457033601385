import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccessDeniedPageComponent } from "./components/access-denied/access-denied.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AuthorizationGuard } from "./_guards/authorization.guard";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";

const routes: Routes = [
  // redirect
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [AuthorizationGuard],
    data: {
      action: PermissionActionEnum.VIEW,
      resource: PermissionResourceEnum.MARKETPLACE,
      subResource: PermissionSecondaryResourceEnum.DETAILS,
    },
    loadChildren: () =>
      import("./modules/marketplace/marketplace.module").then(
        (m) => m.MarketplaceModule
      ),
  },
  {
    path: "form",
    loadChildren: () =>
      import("./modules/forms/forms-fill/forms-fill.module").then(
        (m) => m.FormsFillModule
      ),
  },
  {
    path: "",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./modules/erc/erc.module").then((m) => m.ErcModule),
  },

  {
    path: "",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },

  {
    path: "blog",
    loadChildren: () =>
      import("./modules/blog/blog.module").then((m) => m.BlogModule),
  },

  {
    path: "access-denied",
    component: AccessDeniedPageComponent,
  },
  {
    path: "not-found",
    component: NotFoundComponent,
  },
  {
    path: "account",
    loadChildren: () =>
      import("./modules/account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "rfq",
    loadChildren: () =>
      import("@modules/rfq/rfq.module").then((m) => m.RfqModule),
  },
  {
    path: "**",
    redirectTo: "/home",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: "always",
      anchorScrolling: "enabled",
      scrollOffset: [0, 96],
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
