import { full } from "./enabled-pages";
import { processEnvironment } from "./environment.process";
export const environment = {
  production: false,
  admin_portal_url: "https://admin.dev.xpath.global/",
  marketplace_url: "https://mktp.dev.xpath.global/",
  provider_portal_url: "https://provider.dev.xpath.global/",
  drupal_nestlers_url: "https://blog.xpath.global/",
  gateway_endpoint: "https://gate.dev.xpath.global/",
  client_id: 2,
  client_secret: "JH1QdlJ5gVic2KfQfjtuBxEiRLDA3M0uH85TEXDK",
  marketplace_domain: "bunnyenv",
  pusher_key: "nestlers-messaging-websocketkey",
  wsHost: "msg.dev.xpath.global",
  wsPort: 6001,
  google_tracking_id: "UA-147618337-1",
  expat_terms_url: "https://blog.xpath.global/node/64",
  expat_terms_version: "2020-11-01",
  stripe_public_key: "pk_test_mOF2egzWkFnA3k8f7j4mb9gz00ud5XptS3",
  stripe_portal_login:
    "https://billing.stripe.com/p/login/test_9AQcQh6SQ2sn8jSfYY",
  enabled_pages: full,
  ...(processEnvironment ? processEnvironment : {}),
};
