import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import {
  CitizenshipUpdateModel,
  CitizenshipViewModel,
  CitizenshipsViewModel,
} from "@models/citizenship";
import { ExpatCreate, ExpatModel } from "@models/expat";
import { ExpatMaritalStatusViewModel } from "@models/expat-marital-status";
import { Observable, Subject } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { DictionaryModel } from "../modules/shared/models/dictionary.model";
import { ExpatProvider } from "@modules/providers/models/expat-provider.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const expatsUrl = "entities/expats";

const newExpatsUrl = "entities/clients";

const dependentRelationshipUrl = "entities/directories/dependent/relationships";

@Injectable()
export class ExpatsService {
  public clientCanChangeExpatStatus = false;
  public expatUpdated: Subject<void> = new Subject();

  constructor(private http: HttpClient) {}

  public getExpatsList(clientId: number, params = {}): Observable<any> {
    const parseParams = (data) => {
      let params: HttpParams = new HttpParams();
      for (const key of Object.keys(data)) {
        if (data[key]) {
          if (data[key] instanceof Array) {
            params = params.append(key, data[key].join(";"));
          } else {
            params = params.append(key.toString(), data[key]);
          }
        }
      }
      return params;
    };
    return this.http.get<any>(
      environment.gateway_endpoint + newExpatsUrl + `/${clientId}/expats`,
      {
        params: parseParams(params),
      }
    );
  }

  public getExpatById(id): Observable<ExpatModel> {
    return this.http.get<ExpatModel>(
      environment.gateway_endpoint + expatsUrl + "/" + id
    );
  }

  public getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "entities/expats/status-transition"
    );
  }

  public createExpat(params): Observable<ExpatCreate> {
    return this.http
      .post<ExpatCreate>(
        environment.gateway_endpoint + expatsUrl,
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // When client edits expat details
  public updateExpat(params): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + expatsUrl + "/" + params.id,
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        }),
        finalize(() => this.expatUpdated.next())
      );
  }
  // Delete Expat
  public deleteExpat(data: {
    expatId: number;
    reason: string;
  }): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint + expatsUrl + "/" + data.expatId,
        {
          body: {
            reason: data.reason,
          },
        }
      )
      .pipe();
  }
  // When expat edit its own details
  public updateExpatPATCH(params): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint + expatsUrl + "/update-current-company",
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public updateStatusBulk(status_id, expats_ids, reason = ""): Observable<any> {
    return this.http.patch<any>(
      environment.gateway_endpoint + "entities/expats/updateStatus",
      { status_id: status_id, ids: expats_ids, declined_reason: reason },
      httpOptions
    );
  }

  // GET Expat history
  public getEntityHistory(id: number, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "history/expat/" + id,
      { params: params }
    );
  }

  public getMaritalStatus(
    params = {}
  ): Observable<ExpatMaritalStatusViewModel> {
    return this.http.get<ExpatMaritalStatusViewModel>(
      environment.gateway_endpoint + expatsUrl + "/get-marital-statuses",
      {
        params: params,
      }
    );
  }

  public getEducationForms(params = {}) {
    return this.http.get<any>(
      environment.gateway_endpoint + expatsUrl + "/get-education-forms",
      {
        params: params,
      }
    );
  }

  public getCitizenships(
    expatId: number,
    params = {}
  ): Observable<CitizenshipsViewModel> {
    return this.http.get<CitizenshipsViewModel>(
      environment.gateway_endpoint + expatsUrl + `/${expatId}/citizenships`,
      {
        params: params,
      }
    );
  }

  public getCitizenshipById(
    expatId: number,
    citizenshipId: number,
    params = {}
  ): Observable<CitizenshipViewModel> {
    return this.http.get<CitizenshipViewModel>(
      environment.gateway_endpoint +
        expatsUrl +
        `/${expatId}/citizenships/${citizenshipId}`,
      {
        params: params,
      }
    );
  }

  public updateCitizenship(
    expatId: number,
    citizenshipId: number,
    citizenshipUpdateModel: CitizenshipUpdateModel
  ): Observable<CitizenshipViewModel> {
    return this.http
      .put<CitizenshipViewModel>(
        environment.gateway_endpoint +
          expatsUrl +
          `/${expatId}/citizenships/${citizenshipId}`,
        citizenshipUpdateModel,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public updatePrimaryCitizenship(
    expatId: number,
    citizenshipId: number,
    citizenshipUpdateModel = {}
  ): Observable<CitizenshipViewModel> {
    return this.http
      .put<CitizenshipViewModel>(
        environment.gateway_endpoint +
          expatsUrl +
          `/${expatId}/citizenships/${citizenshipId}/primary`,
        citizenshipUpdateModel,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public createCitizenship(
    expatId: number,
    citizenshipUpdateModel: CitizenshipUpdateModel
  ): Observable<CitizenshipViewModel> {
    return this.http
      .post<CitizenshipViewModel>(
        environment.gateway_endpoint + expatsUrl + `/${expatId}/citizenships`,
        citizenshipUpdateModel,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public deleteCitizenship(
    expatId: number,
    citizenshipId: number
  ): Observable<CitizenshipViewModel> {
    return this.http
      .delete<CitizenshipViewModel>(
        environment.gateway_endpoint +
          expatsUrl +
          `/${expatId}/citizenships/${citizenshipId}`,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public getExpatContacts(
    clientId: number,
    expatId: number,
    params = {}
  ): Observable<any> {
    return this.http
      .get<any>(
        environment.gateway_endpoint +
          `entities/clients/${clientId}/expats/${expatId}/contacts`,
        {
          params: params,
        }
      )
      .pipe(map((_) => _.result.items));
  }

  public createExpatContact(
    clientId: number,
    expatId: number,
    expatContact = {}
  ): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          `entities/clients/${clientId}/expats/${expatId}/contacts`,
        expatContact,
        httpOptions
      )
      .pipe(map((_) => _.result));
  }

  public updateExpatContact(
    clientId: number,
    expatId: number,
    contactId: number,
    expatContact = {}
  ): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          `entities/clients/${clientId}/expats/${expatId}/contacts/${contactId}`,
        expatContact,
        httpOptions
      )
      .pipe(map((_) => _.result));
  }

  public deleteExpatContact(
    clientId: number,
    expatId: number,
    contactId: number
  ): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          `entities/clients/${clientId}/expats/${expatId}/contacts/${contactId}`
      )
      .pipe(map((_) => _.result));
  }

  public getExpatDependents(expatId: number, params = {}): Observable<any> {
    return this.http
      .get<any>(
        environment.gateway_endpoint + `entities/expats/${expatId}/dependents`,
        {
          params: params,
        }
      )
      .pipe(map((_) => _.result.dependents));
  }

  public deleteExpatDependents(
    expatId: number,
    dependentId: number
  ): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          `entities/expats/${expatId}/dependents/${dependentId}`
      )
      .pipe(map((_) => _.result));
  }

  public createExpatDependents(params, expatId: number): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + `entities/expats/${expatId}/dependents`,
        params,
        httpOptions
      )
      .pipe(map((res) => res.result.dependent));
  }
  public updateExpatDependents(
    params,
    expatId: number,
    dependentId: number
  ): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          `entities/expats/${expatId}/dependents/${dependentId}`,
        params,
        httpOptions
      )
      .pipe(map((res) => res.result.dependent));
  }

  public getExpatDependentRelationships(
    params = {}
  ): Observable<DictionaryModel[]> {
    return this.http
      .get<any>(environment.gateway_endpoint + dependentRelationshipUrl, {
        params: params,
      })
      .pipe(map((_) => _.result));
  }

  public export(params = {}, clientId) {
    return this.http.get(
      environment.gateway_endpoint +
        "entities/clients/" +
        clientId +
        "/expats/export",
      {
        responseType: "blob",
        params: params,
      }
    );
  }

  public inviteExpat(id) {
    return this.http.post<any>(
      environment.gateway_endpoint + "entities/expats/invite",
      {
        expat_ids: id,
      }
    );
  }

  public getProvidersList(expatId: number, params?) {
    return this.http
      .get(
        environment.gateway_endpoint +
          "entities/expats/" +
          expatId +
          "/providers",
        {
          params,
        }
      )
      .pipe(map((res: { result: ExpatProvider[] }) => res.result));
  }
}
