import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Params } from "@angular/router";
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  lastValueFrom,
} from "rxjs";

import { DOCUMENT } from "@angular/common";
import {
  CaseTaskData,
  CaseTaskMapperService,
  CaseTasksService,
} from "@api/case-tasks";
import { TimelineCaseTask } from "@api/case-tasks/models/timeline-case-task.model";
import { TimelineMapperService } from "@api/case-tasks/services/timeline-mapper.service";
import { CaseData, CasesService, GroupedCaseService } from "@api/cases";
import { ValueList } from "@modules/shared/models/value-list.model";
import { finalize, map, shareReplay, tap } from "rxjs/operators";

@Injectable()
export class TimelineService {
  onTimelineChanged: BehaviorSubject<any>;
  onServicesChanged: BehaviorSubject<any>;
  onTimelineCalendarDateChanged: Subject<any>;

  public timelines: TimelineCaseTask[];
  public services: GroupedCaseService<TimelineCaseTask>[];
  public case: CaseData;
  private timelineResult: CaseTaskData[];

  onTimelineWidthChanged: Subject<any>;
  public sideGridTotalWidth: string | number = "2200";

  public currentMonthHolder: Date = new Date();
  public currentMonthDays: any;
  public days = ["S", "M", "T", "W", "T", "F", "S"];
  public currentMonth: Date = new Date();
  public currentMonthLastDay = 0;

  public previousMonth: Date = new Date();
  public nextMonth: Date = new Date();

  public isTimelineLoading = false;
  public timelineHeaderDaysPositions = [];

  public isSidebarOpen = false;
  public isAllServicesActive = true;

  public params: Params = [];
  case$: Observable<CaseData>;
  constructor(
    private http: HttpClient,
    private timelineMapperService: TimelineMapperService,
    private caseTaskMapperService: CaseTaskMapperService,
    private caseTaskService: CaseTasksService,
    private caseService: CasesService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Set the defaults
    this.onTimelineChanged = new BehaviorSubject([]);
    this.onServicesChanged = new BehaviorSubject([]);

    this.onTimelineCalendarDateChanged = new Subject();
    this.onTimelineWidthChanged = new Subject();

    this.getAllDaysInMonth();
  }

  /**
   * Get all days for the current month
   *
   * @returns {any}
   */
  public getAllDaysInMonth(selectedMonth?: number): void {
    if (selectedMonth) {
      this.currentMonth = new Date(
        this.currentMonth.getFullYear(),
        this.currentMonth.getMonth() + selectedMonth
      );
      this.onTimelineCalendarDateChanged.next(true);
    }

    this.previousMonth.setMonth(this.currentMonth.getMonth() - 1);
    this.nextMonth.setMonth(this.currentMonth.getMonth() + 1);

    const year = this.currentMonth.getFullYear();
    const month = this.currentMonth.getMonth() + 1;

    this.currentMonthDays = Array.from(
      { length: new Date(year, month, 0).getDate() },
      (_, i) => new Date(year, month - 1, i + 1)
    );

    this.currentMonthLastDay = new Date(
      this.currentMonth.getFullYear(),
      this.currentMonth.getMonth() + 1,
      0
    ).getDate();

    /**
     * Get the new timeline ony when date has been changed
     */
    if (selectedMonth) {
      this.getTimelines().then();
    }
  }

  /**
   * Find the timeline task and mark  it as open
   *
   * @returns {any}
   */
  public openTask(item: CaseTaskData): any {
    this.timelines.forEach((timeline: CaseTaskData) => {
      if (timeline.id === item.id) {
        timeline["active"] = !timeline["active"];
      }
    });
  }

  /**
   * Toggle sidebar
   */
  public toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  /**
   * Return array of items per service
   */
  public filterTimelinesPerService(
    service?: GroupedCaseService<TimelineCaseTask>
  ) {
    this.services.map((mappedService) => (mappedService.active = false));

    if (service) {
      service.active = true;

      this.timelines = this.services.find(
        (item) => item.service_id === service.service_id
      ).tasks;
      this.timelines = this.timelineMapperService.mapMany(
        this.timelines,
        this.currentMonth
      );

      this.isAllServicesActive = false;
    } else {
      this.timelines = this.timelineMapperService.mapMany(
        this.timelineResult,
        this.currentMonth
      );
      this.isAllServicesActive = true;
    }

    this.onTimelineChanged.next(this.timelines);
  }

  /**
   * Set array of positions for each date
   * This function runs only when last month of the timeline is created, to get the natural position of whole elements
   */
  public setEachDaysPositions() {
    this.timelineHeaderDaysPositions = [];

    const timelineHeaderRef = this.document.querySelector(
      ".header"
    ) as HTMLElement;
    this.currentMonthDays.forEach((day: Date) => {
      const timelineHeaderDayRefElement = timelineHeaderRef.querySelector(
        `.header__dates_${day.getDate()}`
      ) as HTMLElement;

      if (timelineHeaderDayRefElement) {
        const timelinePositionDayArray = {
          day: day.getDate(),
          startPosition: timelineHeaderDayRefElement.offsetLeft,
        };
        this.timelineHeaderDaysPositions.push(timelinePositionDayArray);
      }
    });
  }

  /**
   * Return the position of each timeline
   */
  public formatTimelinePositions(item: TimelineCaseTask) {
    let positionOfTimelineStartElement: number;
    if (item.isSubTask && item.isPreviousMonthOverlapped) {
      positionOfTimelineStartElement = -59.5;
    } else if (item.isPreviousMonthOverlapped && !item.isSubTask) {
      positionOfTimelineStartElement = 0;
    } else if (item.isSubTask) {
      positionOfTimelineStartElement = -40;
    } else if (item.isNextMonthOverlapped) {
      positionOfTimelineStartElement = 23;
    } else if (item.isPreviousMonthOverlapped) {
      positionOfTimelineStartElement = 0;
    } else {
      positionOfTimelineStartElement = 15;
    }

    let positionOfTimelineWidthElement: number;
    if (item.isSubTask && item.isNextMonthOverlapped) {
      positionOfTimelineWidthElement = 205;
    } else if (
      item.isPreviousMonthOverlapped &&
      !item.isNextMonthOverlapped &&
      !item.isSubTask
    ) {
      positionOfTimelineWidthElement = 60;
    } else if (
      item.isPreviousMonthOverlapped &&
      item.isNextMonthOverlapped &&
      !item.isSubTask
    ) {
      positionOfTimelineWidthElement = 150;
    } else if (item.isSubTask) {
      positionOfTimelineWidthElement = 85;
    } else if (item.isNextMonthOverlapped) {
      positionOfTimelineWidthElement = 80;
    } else {
      positionOfTimelineWidthElement = 30;
    }

    /**
     * When it's a task and the end date it's the first
     */
    if (
      item.isPreviousMonthOverlapped &&
      item.endDateDay === 1 &&
      !item.isSubTask
    ) {
      positionOfTimelineWidthElement = 60;
    }

    let totalWidthOfEndPosition =
      positionOfTimelineStartElement + positionOfTimelineWidthElement;

    /**
     * When it's a sub-task and te end date it's the first
     */
    if (
      item.isPreviousMonthOverlapped &&
      item.endDateDay === 1 &&
      item.isSubTask
    ) {
      totalWidthOfEndPosition = positionOfTimelineStartElement + 115;
    }

    return {
      positionOfTimelineStartElement: positionOfTimelineStartElement,
      positionOfTimelineWidthElement: positionOfTimelineWidthElement,
      totalWidthOfEndPosition: totalWidthOfEndPosition,
    };
  }

  /**
   * Get timelines
   *
   * @returns {Promise<any>}
   */
  public getTimelines(): Promise<any> {
    this.isTimelineLoading = true;

    const params: ValueList<string> = {
      period: `${this.getFormattedDate.startDate};${this.getFormattedDate.endDate}`,
      parent_id: null,
      include: "",
    };

    if (this.params.clientId) {
      params.client_id = this.params.clientId;
    }
    if (this.params.case) {
      params.expat_case_id = this.params.case;
    }

    return lastValueFrom(
      combineLatest([this.case$, this.caseTaskService.list(params)]).pipe(
        map(([expatCase, response]) =>
          this.caseTaskMapperService.fillCaseService(
            expatCase.services,
            response.items
          )
        ),
        tap((tasks) => (this.timelineResult = tasks)),
        map((tasks) => {
          return this.timelineMapperService.mapMany(tasks, this.currentMonth);
        }),
        tap((tasks) => {
          this.timelines = tasks;
          this.onTimelineChanged.next(this.timelines);
          this.services = this.caseTaskMapperService.groupByServices(tasks);
          this.onServicesChanged.next(this.services);
          this.isTimelineLoading = false;
        }),
        finalize(() => (this.isTimelineLoading = false))
      )
    );
  }

  /**
   * Get Case Details
   */
  public getCaseDetails(params): Observable<CaseData> {
    return (this.case$ = this.caseService.details(params.case).pipe(
      tap((expatCase) => (this.case = expatCase)),
      shareReplay(1)
    ));
  }

  /**
   * Get the start_date & end_date for timeline tasks
   */
  private get getFormattedDate(): { endDate: string; startDate: string } {
    const dd = 1;
    const mm = this.currentMonth.toLocaleDateString("default", {
      month: "long",
    });
    const yyyy = this.currentMonth.getFullYear();
    const startDate = new Date(`${mm} ${dd}, ${yyyy} 12:00:00`);

    const edd = new Date(yyyy, this.currentMonth.getMonth() + 1, 0).getDate();
    const endDate = new Date(`${mm} ${edd}, ${yyyy} 12:00:00`);

    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
  }
}
