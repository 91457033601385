import { isPlatformBrowser } from "@angular/common";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ValueList } from "@modules/shared/models/value-list.model";
import { filter } from "rxjs/operators";

interface HistoryItem {
  resource: string;
  query: ValueList<string>;
}

@Injectable({
  providedIn: "root",
})
export class QueryParamsService {
  private history: HistoryItem[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.history = this.storageData();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.handleNavigationEndEvent(event);
        this.syncStorage();
      });
  }

  public getQueryParamsAndRemoveUnnecessary(
    resource: string
  ): ValueList<string> {
    const historyItem = this.history.find(
      (item: HistoryItem) => item.resource === resource
    );

    const index = this.history.indexOf(historyItem);

    this.history = this.history.slice(0, index);

    this.syncStorage();

    return historyItem.query;
  }

  public get mainResource(): HistoryItem {
    return this.history[0];
  }

  private syncStorage(): void {
    const stringHistory = JSON.stringify(this.history);

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    localStorage.setItem("queryParamsHistory", stringHistory);
  }

  private storageData(): HistoryItem[] {
    if (!isPlatformBrowser(this.platformId)) {
      return [] as HistoryItem[];
    }
    const stringHistory = localStorage?.getItem("queryParamsHistory");

    if (!stringHistory) {
      return [] as HistoryItem[];
    }

    return JSON.parse(stringHistory) as HistoryItem[];
  }

  private handleNavigationEndEvent(event: NavigationEnd): void {
    const resource: string = this.getLastResourceFromUrl(
      event.urlAfterRedirects
    );

    const query: ValueList<string> = this.route.snapshot.queryParams;

    if (this.isMainResource(resource, event.urlAfterRedirects)) {
      this.history = [{ resource, query }];

      return;
    }

    const historyItem = this.history.find(
      (item: HistoryItem) => item.resource === resource
    );

    if (historyItem) {
      this.history = this.history.map((item: HistoryItem) =>
        item.resource === resource ? { ...item, query } : item
      );

      return;
    }

    this.history.push({ resource, query });
  }

  private getLastResourceFromUrl(url: string): string {
    const resources = this.getResources(url);

    return resources[resources.length - 1];
  }

  private isMainResource(resource: string, url: string): boolean {
    const resources = this.getResources(url);

    return resources[0] === resource;
  }

  private getResources(url: string): string[] {
    let resources = url.split("/");

    resources = resources
      .map((item: string) => (item.includes("?") ? item.split("?")[0] : item))
      .filter((item: string) => isNaN(Number(item)));

    return resources;
  }
}
