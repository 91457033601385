import { isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

interface SvgItem {
  name: string;
  path: string;
}

@Injectable()
export class SvgRegistryService {
  private baseIconsPath: string = "assets/icons";

  private svgList: SvgItem[] = [
    {
      name: "menu-icon-dashboard",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-dashboard.svg`,
    },

    {
      name: "menu-icon-assignments",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-assignments.svg`,
    },
    {
      name: "menu-icon-orders",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-orders.svg`,
    },
    {
      name: "menu-icon-cases",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-cases.svg`,
    },
    {
      name: "menu-icon-tasks",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-tasks.svg`,
    },
    {
      name: "menu-icon-processes",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-processes.svg`,
    },
    {
      name: "menu-icon-benefits",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-benefits.svg`,
    },
    {
      name: "menu-icon-cost-projections",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-cost-projections.svg`,
    },
    {
      name: "menu-icon-providers",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-providers.svg`,
    },
    {
      name: "menu-icon-rfq-bids",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-rfq-bids.svg`,
    },
    {
      name: "menu-icon-templates",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-templates.svg`,
    },
    {
      name: "menu-icon-reports",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-reports.svg`,
    },
    {
      name: "menu-icon-users",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-users.svg`,
    },
    {
      name: "menu-icon-settings",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-settings.svg`,
    },
    {
      name: "menu-icon-help",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-help.svg`,
    },
    {
      name: "menu-icon-tax-calendar",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-tax-calendar.svg`,
    },
    {
      name: "menu-icon-billing",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-billing.svg`,
    },
    {
      name: "menu-icon-documents",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-documents.svg`,
    },
    {
      name: "menu-icon-temp-housing",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-temp-housing.svg`,
    },
    {
      name: "approve",
      path: `${this.baseIconsPath}/save.svg`,
    },
    {
      name: "arrow-right",
      path: `${this.baseIconsPath}/arrow-right.svg`,
    },
    {
      name: "arrow-right-outline",
      path: `${this.baseIconsPath}/arrow-right-outline.svg`,
    },
    {
      name: "arrow-up",
      path: `${this.baseIconsPath}/cost-projection-up-arrow.svg`,
    },
    {
      name: "bookmark",
      path: `${this.baseIconsPath}/bookmark.svg`,
    },
    {
      name: "calendar",
      path: `${this.baseIconsPath}/calendar.svg`,
    },
    {
      name: "cancel",
      path: `${this.baseIconsPath}/cancel.svg`,
    },
    {
      name: "cash",
      path: `${this.baseIconsPath}/cash-outline.svg`,
    },
    {
      name: "CheckCircle",
      path: `${this.baseIconsPath}/CheckCircle.svg`,
    },
    {
      name: "chevron-down",
      path: `${this.baseIconsPath}/ChevronDown.svg`,
    },
    {
      name: "clipboard",
      path: `${this.baseIconsPath}/assignment-type.svg`,
    },
    {
      name: "Clock",
      path: `${this.baseIconsPath}/Clock.svg`,
    },
    {
      name: "content-copy",
      path: `${this.baseIconsPath}/content-copy.svg`,
    },
    {
      name: "currency-dollar",
      path: `${this.baseIconsPath}/currency-dollar.svg`,
    },
    {
      name: "doc-icon",
      path: `${this.baseIconsPath}/doc-icon.svg`,
    },
    {
      name: "download",
      path: `${this.baseIconsPath}/download.svg`,
    },
    {
      name: "download-icon",
      path: `${this.baseIconsPath}/download-icon.svg`,
    },
    {
      name: "drag-handle",
      path: `${this.baseIconsPath}/drag-handle.svg`,
    },
    {
      name: "edit",
      path: `${this.baseIconsPath}/edit-icon.svg`,
    },
    {
      name: "email",
      path: `${this.baseIconsPath}/email.svg`,
    },
    {
      name: "estimated-duration",
      path: `${this.baseIconsPath}/estimated-duration.svg`,
    },
    {
      name: "estimated-end-date",
      path: `${this.baseIconsPath}/estimated-end-date.svg`,
    },
    {
      name: "estimated-start-date",
      path: `${this.baseIconsPath}/estimated-start-date.svg`,
    },
    {
      name: "export",
      path: `${this.baseIconsPath}/export.svg`,
    },
    {
      name: "external-link",
      path: `${this.baseIconsPath}/external-link-outline.svg`,
    },
    {
      name: "eye",
      path: `${this.baseIconsPath}/eye-outline.svg`,
    },
    {
      name: "filter",
      path: `${this.baseIconsPath}/filter.svg`,
    },
    {
      name: "info",
      path: `${this.baseIconsPath}/info.svg`,
    },

    {
      name: "edit",
      path: `${this.baseIconsPath}/edit.svg`,
    },
    {
      name: "in-progress",
      path: `${this.baseIconsPath}/task-status-in-progress.svg`,
    },
    {
      name: "lightning-bolt",
      path: `${this.baseIconsPath}/lightning-bolt.svg`,
    },
    {
      name: "list",
      path: `${this.baseIconsPath}/list.svg`,
    },
    {
      name: "link-icon",
      path: `${this.baseIconsPath}/link-icon.svg`,
    },
    {
      name: "location",
      path: `${this.baseIconsPath}/location-marker-outline.svg`,
    },
    {
      name: "pending",
      path: `${this.baseIconsPath}/task-status-pending.svg`,
    },
    {
      name: "pdf-color",
      path: `${this.baseIconsPath}/pdf_icon.svg`,
    },
    {
      name: "phone",
      path: `${this.baseIconsPath}/phone.svg`,
    },
    {
      name: "plus-circle",
      path: `${this.baseIconsPath}/mat-icon-plus-circle.svg`,
    },
    {
      name: "plus-circle-outline",
      path: `${this.baseIconsPath}/plus-circle-outline.svg`,
    },
    {
      name: "put on hold",
      path: `${this.baseIconsPath}/access-denied.svg`,
    },
    {
      name: "puzzle",
      path: `${this.baseIconsPath}/puzzle.svg`,
    },
    {
      name: "people",
      path: `${this.baseIconsPath}/people.svg`,
    },
    {
      name: "receipt",
      path: `${this.baseIconsPath}/receipt-outline.svg`,
    },
    {
      name: "refresh",
      path: `${this.baseIconsPath}/refresh-outline.svg`,
    },
    {
      name: "reject",
      path: `${this.baseIconsPath}/access-denied.svg`,
    },
    {
      name: "resume",
      path: `${this.baseIconsPath}/refresh-outline.svg`,
    },
    {
      name: "save",
      path: `${this.baseIconsPath}/save.svg`,
    },
    {
      name: "save-outline",
      path: `${this.baseIconsPath}/save-outline.svg`,
    },
    {
      name: "share-icon",
      path: `${this.baseIconsPath}/share-icon.svg`,
    },
    {
      name: "star",
      path: `${this.baseIconsPath}/star-outline.svg`,
    },
    {
      name: "subtasks",
      path: `${this.baseIconsPath}/subtask-count.svg`,
    },
    {
      name: "settings",
      path: `${this.baseIconsPath}/settings.svg`,
    },
    {
      name: "tag",
      path: `${this.baseIconsPath}/tag-outline.svg`,
    },
    {
      name: "trash",
      path: `${this.baseIconsPath}/trash.svg`,
    },

    {
      name: "upload",
      path: `${this.baseIconsPath}/cloud-upload-outline.svg`,
    },
    {
      name: "user",
      path: `${this.baseIconsPath}/user.svg`,
    },
    {
      name: "user-add",
      path: `${this.baseIconsPath}/user-add-outline.svg`,
    },
    {
      name: "user-outline",
      path: `${this.baseIconsPath}/user-outline.svg`,
    },
    {
      name: "ViewList",
      path: `${this.baseIconsPath}/ViewList.svg`,
    },
    {
      name: "warning",
      path: `${this.baseIconsPath}/warning.svg`,
    },

    {
      name: "sort-up",
      path: `${this.baseIconsPath}/sort-up.svg`,
    },
    {
      name: "sort-down",
      path: `${this.baseIconsPath}/sort-down.svg`,
    },
    {
      name: "search",
      path: `${this.baseIconsPath}/search.svg`,
    },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  public init() {
    const domain = isPlatformServer(this.platformId)
      ? "http://localhost:4200/"
      : "";
    this.svgList.forEach((svg: SvgItem) => {
      this.matIconRegistry.addSvgIcon(
        svg.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(domain + svg.path)
      );
    });
  }
}
