import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountActions } from "@api/account/actions";
import { AccountSelectors } from "@api/account/selectors";
import { BackendHttpClient } from "@api/http/backend-http-client";
import { UserData } from "@api/users/models/user.model";
import { environment } from "@environment/environment";
import { Expat } from "@models/expat";
import { ResetPassword } from "@models/reset-password";
import { Store } from "@ngrx/store";
import { Observable, catchError, finalize, map, throwError } from "rxjs";
import { AuthActions } from "../actions";
import { AccountStoreService } from "@api/account/services/account-store.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public currentUser: Observable<UserData>;

  constructor(
    private httpClient: BackendHttpClient,
    private store: Store,
    private accountStorage: AccountStoreService,
    private http: HttpClient
  ) {
    this.currentUser = this.store.select(AccountSelectors.selectUser);
  }

  /**
   * Login
   *
   * @param username
   * @param password
   * @returns {any}
   */
  login(username: string, password: string): any {
    const body = {
      username: username,
      password: password,
    };

    return this.httpClient.post<any>("login", body, this.setOptions()).pipe(
      map((data) => {
        // login successful if there's a jwt token in the response
        if (data && data.result.session.token) {
          this.store.dispatch(
            AuthActions.authorized({ token: data.result.session.token })
          );

          this.store.dispatch(AccountActions.reloadAccount());
        }

        return data;
      })
    );
  }

  /**
   * Register step 1
   * @param user
   */
  register(user: UserData): any {
    return this.httpClient
      .post<any>("entities/clients/register", user, this.setOptions())
      .pipe();
  }

  /**
   * Set headers for request
   */
  public setOptions() {
    const x_client_secret = btoa(
      environment.client_secret + ":" + environment.client_id + ":Marketplace"
    );

    return {
      headers: new HttpHeaders({
        "content-type": "application/json",
        "x-client-secret": x_client_secret,
      }),
    };
  }

  /**
   * Logout
   */
  logout(errStatus?) {
    return this.httpClient.post<any>("users/logout", {}).pipe(
      finalize(() => {
        this.store.dispatch(AuthActions.logout());
      }),
      map((model) => {
        return model;
      }),
      catchError((error, caught) => {
        return throwError(() => caught);
      })
    );
  }

  /**
   * Check if the user has a specific permission
   * @param action
   * @param resource
   * @param secondaryResource
   */
  public $can(
    action: string,
    resource: string,
    secondaryResource: string
  ): Observable<boolean> {
    const permission = `${action} ${resource} ${secondaryResource}`;
    return this.currentUser.pipe(
      map((user) => user.permissions.indexOf(permission) > -1)
    );
  }

  removeLocalStorage() {
    this.accountStorage.clearStore();
  }

  sendResetPasswordEmail(email: string) {
    return this.http
      .post<any>(environment.gateway_endpoint + "users/password/request", {
        username: email,
      })
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  resendConfirmationMail(id) {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}users/${id}/send-confirmation-mail`,
        {}
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  mailConfirmation(e): any {
    return this.http
      .post<any>(environment.gateway_endpoint + "confirm-email", e)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  save(data) {
    if (data && data.session.token) {
      // store user details and jwt token in user store to keep user logged in between page refreshes
      this.store.dispatch(AuthActions.authorized({ token: data.session }));
      this.store.dispatch(
        AccountActions.updateAccountDataSuccess({ user: data.user })
      );
    }
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "resetPassword",
        resetPassword,
        this.setOptions()
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Register Expat
  registerExpat(expat: Expat): any {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "entities/expats/register",
        expat,
        this.setOptions()
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
